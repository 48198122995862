<template>
	<div class="wrapper" v-loading="loading">
		<div v-if="!orgId" class="top-btn" ref="topBtn">
			<div>
				<el-select class="w110" v-model="search.search_gist" filterable placeholder="请选择" size="small">
					<el-option v-for="it in searchGist" :key="it.value" :label="it.label" :value="it.value"></el-option>
				</el-select>
				<el-input class="w140" size="small" placeholder="请输入名称" v-model="search.desp" clearable> </el-input>
				<el-button size="small" type="primary" @click="Search()">搜索</el-button>
			</div>
			<div>
				<span class="demonstration">时间：</span>
				<el-date-picker size="small" style="width: 230px" @change="Search()"
					v-model="search.time"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
		</div>
		<el-table :data="list" :max-height="tableHeight" :header-cell-style="{background:'#f5f7fa'}" min-width="100%">
			<el-table-column prop="created_at" label="时间">
				<template slot-scope="scope">{{ scope.row.created_at*1000 | formatDate }}</template>
			</el-table-column>
			<el-table-column prop="logo" label="logo">
				<template slot-scope="scope">
					<img :src="scope.row.logo || ''" style="width: 50px;border-radius: 50%;" alt="" />
				</template>
			</el-table-column>
			<el-table-column prop="org_id" label="机构id"></el-table-column>
			<el-table-column prop="org_name" label="机构名称"></el-table-column>
			<el-table-column prop="org_phone" label="机构电话"></el-table-column>
			<el-table-column prop="before_credit" label="变动前积分"></el-table-column>
			<el-table-column prop="credit" label="变动次数"></el-table-column>
			<el-table-column prop="after_credit" label="变动后次数"></el-table-column>
			<el-table-column prop="desp" label="明细内容">
				<template slot-scope="scope">{{scope.row.desp}}</template>
			</el-table-column>
		</el-table>
		<el-pagination v-if="total > 0" style="padding-top: 15px; text-align: center;" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="limit" layout="prev, pager, next, jumper" :total="total">
		</el-pagination>
	</div>
</template>
<script>
	import { orgCreditList } from '@/api/institutional.js';
	export default {
		name: "OrgCreditList",
		props: {
			orgId: { // 机构详情里
				type: String,
				default: ''
			},
		},
		data() {
			return {
				loading: true,
				tableHeight: this.$constant.maxHeight,
				//搜索依据
				searchGist: [{
					value: '机构id',
					label: '机构id'
				}, {
					value: '机构名',
					label: '机构名'
				}, {
					value: '手机号',
					label: '手机号'
				}],
				// 列表
				search: {
					search_gist: '机构名',
					desp: '',
					time: '',
				},
				// 列表
				total: 0,
				limit: 10,
				currentPage: 1,
				list: [],
				dialogVisible: false,
				id: 0
			}
		},
		mounted() {
			this.getList()
			this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			window.onresize = this.$common.debounce(()=>{
				this.tableHeight = this.orgId ? this.$common.setHeight([], 250) : this.$common.setHeight([this.$refs.topBtn], 130)
			}, 1000)
		},
		methods: {
			// 获取列表
			getList() {
				let data = {}
				if(this.orgId) {
					data = {
						limit: this.limit,
						page: this.currentPage,
						org_id: this.orgId
					}
				}else {
					data = {
						limit: this.limit,
						page: this.currentPage,
						org_name: this.search.search_gist == '机构名'?this.search.desp:'', //昵称搜索
						org_phone:  this.search.search_gist == '手机号'?this.search.desp:'', //电话搜索
						org_id:  this.search.search_gist == '机构id'?this.search.desp:'', //电话搜索
						start_time: this.$common.setSearchTime(this.search.time)[0],
						end_time: this.$common.setSearchTime(this.search.time)[1]
					}
				}
				orgCreditList(data).then((res) => {
					this.total = res.data.total;
					this.list = res.data.data;
					this.loading = false;
				}).catch((err) => {
					this.loading = false;
					console.log(err, 222)
				})
			},
			// 筛选
			Search(){
				this.loading = true
				this.currentPage = 1
				this.getList()
			},
			// 页码修改
			handleCurrentChange(val) {
				this.loading = true
				this.currentPage = val;
				this.getList();
			},
		}
	};
</script>
