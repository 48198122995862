import request from '@/util/request'

// 获取机构列表
export function orgList(params) {
  return request({
    url: '/admin/v1/orgList',
    method: 'GET',
    params,
  })
}

export function simpleorgList(params) {
  return request({
    url: '/admin/v1/org/list',
    method: 'GET',
    params,
  })
}

// 新增编辑机构
export function saveOrg(data) {
  return request({
    url: '/admin/v1/saveOrg',
    method: 'POST',
    data,
  })
}

// 获取机构详情
export function orgInfo(params) {
  return request({
    url: '/admin/v1/orgInfo',
    method: 'GET',
    params,
  })
}

// 删除机构
export function delOrg(params) {
  return request({
    url: '/admin/v1/delOrg',
    method: 'DELETE',
    params,
  })
}

// 上下架机构
export function onOrOffOrg(data) {
  return request({
    url: '/admin/v1/onOrOffOrg',
    method: 'POST',
    data,
  })
}
// 机构广告积分明细
export function orgCreditList(params) {
  return request({
    url: '/admin/v1/marketing/orgCreditList',
    method: 'get',
    params,
  })
}
// 增减广告积分
export function score(data) {
  return request({
    url: '/admin/v1/org/score',
    method: 'post',
    data,
  })
}
// 修改推荐词
export function recWord(data) {
  return request({
    url: '/admin/v1/org/recWord',
    method: 'post',
    data,
  })
}
// 获取机构员工
export function userList(params) {
  return request({
    url: '/admin/v1/org/userList',
    method: 'get',
    params,
  })
}
// 获取机构所有角色
export function orgRole(params) {
  return request({
    url: '/admin/v1/org/role',
    method: 'get',
    params,
  })
}
// 踢出员工
export function kickOut(data) {
  return request({
    url: '/admin/v1/org/kickOut',
    method: 'post',
    data,
  })
}
// 修改权限
export function changRole(data) {
  return request({
    url: '/admin/v1/org/changeRole',
    method: 'post',
    data,
  })
}
// 修改员工备注名
export function changRealName(data) {
  return request({
    url: '/admin/v1/org/changeRealName',
    method: 'post',
    data,
  })
}

// 修改员工备注名
export function orgFans(params) {
  return request({
    url: '/admin/v1/org/fans',
    method: 'get',
    params,
  })
}

// 获取积分兑换配置
export function orgScoreConfList(params) {
  return request({
    url: '/admin/v1/orgScoreConf/get',
    method: 'get',
    params,
  })
}

// 获取积分兑换配置
export function orgScoreConfSave(data) {
  return request({
    url: '/admin/v1/orgScoreConf/save',
    method: 'POST',
    data,
  })
}

// 获取邀日志
export function getInvite(params) {
  return request({
    url: '/admin/v1/org/getInvite',
    method: 'get',
    params,
  })
}

//  获取机构下相册
export function getAlbumList(params) {
  return request({
    url: '/admin/v1/org/getAlbumList',
    method: 'get',
    params,
  })
}

// 保存机构相册
export function saveOrgAlbum(data) {
  return request({
    url: '/admin/v1//org/saveOrgAlbum',
    method: 'POST',
    data,
  })
}

// 删除机构相册
export function delOrgAlbum(params) {
  return request({
    url: '/admin/v1/org/delOrgAlbum',
    method: 'delete',
    params,
  })
}